.mapboxgl-ctrl-geocoder {
  min-width: 180px!important;
  width: 225px!important;
}

@media screen and (max-width: 400px) {
  .mapboxgl-ctrl-geocoder {
    min-width: 150px!important;
    width: 150px!important;
  }
}
